import { fb } from '../../../lib/form';
export function getCategoryListModel(data, defaultCategories) {

  var options = []; //{name : 'Favourites', value : '_favourites_'}

  if (data.length > 0) {
    
    let _allCategory = null;
    data.forEach(el => {
      if (el.categoryName.toLowerCase() !== 'hair products' && el.categoryName.toLowerCase() !== 'all') {
        options.push({ name: el.categoryName, value: el.categoryId });
      }

      if(el.categoryName.toLowerCase() === 'all'){
        _allCategory = { name: el.categoryName, value: el.categoryId };
      }

    });

    if(_allCategory !== null){
      options.unshift(_allCategory);
    }

  }

  let categoryList = "";
  if (defaultCategories.length > 0) {
    categoryList = defaultCategories[0];
  }

  const model = fb.group({
    categoryList: [categoryList, [], {
      label: 'FILTER BY',
      type: 'select',
      options: options
    }]
  });
  return model;
}