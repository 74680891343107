import React from 'react';
import cmsUtils from '../../utils/cmsUtils';
import utils from '../../../utils';
import { SiteLink } from '../../../components';

export function BannerItem(props) {
  const item = props.item;
  const cmsOption = props.cmsOption || {};

  //console.log('item', item);

  const bgUrl = utils.site.resourcePath(cmsUtils.payload(item, 'ImageUrl'));

  const captionTitle = cmsUtils.payload(item, 'CaptionTitle2') || cmsUtils.payload(item, 'CaptionTitle');
  const captionButton = cmsUtils.payload(item, 'CaptionButton') && cmsUtils.payload(item, 'CaptionButton') !== "" ? cmsUtils.payload(item, 'CaptionButton') : "READ MORE";
  
  const linkUrl = cmsUtils.payload(item, 'LinkUrl2') || cmsUtils.payload(item, 'LinkUrl');

  const titlePanel = captionTitle ? (
    linkUrl ? (<div>
      <div className="banner-item__title" dangerouslySetInnerHTML={{ __html: captionTitle }}></div>
      <SiteLink to={linkUrl} className="banner-item__link"> { captionButton } </SiteLink>
    </div>
    ) : (<div className="banner-item__title" dangerouslySetInnerHTML={{ __html: captionTitle }}></div>)
  ) : null;

  const linkOverlay = linkUrl && !captionTitle ? (
    <SiteLink className="bannerItem__overlay" to={linkUrl}></SiteLink>
  ) : null;

  const cssClass = utils.classNames('cms_item', 'banner-item', 'bannerSize', item.cssClass || item.anchorName || '', titlePanel && 'bannerHasTitlePanel');

  return (
    <div className={cssClass} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}
      style={{ backgroundImage: utils.css.bgUrlStyle(bgUrl) }}>
      {titlePanel && <div className="banner_item__text">{titlePanel}</div>}
      {linkOverlay && linkOverlay}
    </div>
  );
}