/** @jsx jsx */
import { jsx } from '@emotion/core';
import utils from '../../../utils';
import { SiteLink } from '../../../components';
import { tileStyle } from './productTileStyle';
//import utils from '../../../utils';

export function ProductTile({ product }) {
  const s = tileStyle;
  const imageUrl = product.thumbnailUrl ? utils.site.resourcePath(product.thumbnailUrl) : '/assets/product-empty.png';
  //console.log("product : ", product)
  const productUrl = product.productUrl ? product.productUrl : `/shop/${product.productSlug}`;
  return (
    <div css={[s.tile]}>
      <SiteLink css={s.tileLink} to={productUrl}>
        
        <div css={s.prdImage}>
          <div className="bgImage" css={s.bg} style={{ backgroundImage: utils.css.bgUrlStyle(imageUrl) }}></div>
        </div>
        
        <div css={s.desContainer}>
          
          <div css={s.name}>
            {product.productName}
          </div>
          
          <div css={s.shortDes}>
            {/* {product.shortDesc} */}
          </div>
          
          <div css={s.price}>
            {
              product.productStatus !== 0 ? product.productStatusText :
                product.priceTextOveriding ? product.priceTextOveriding : 'AU' + utils.money.formatMoney(product.priceIncTax)
            }
          </div>

          {
            product.productStatus === 0 &&
            <div className="buyNowBtn" css={s.buyNowButton}>
              BUY NOW
            </div>
          }

        </div>

      </SiteLink>
    </div>
  )
}