import { css  } from '@emotion/core'
import { mq } from '../../cssInJs'

export default {
  top: css(mq({
    //height: [44, null, 80],
    height: [44, null, 56],
    background: 'white',
    position: 'fixed',
    width: '100%',
    zIndex: '999',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'all 0.3s',
    top:0
  })),
  // topScrolled: css(mq({
  //   height: [44, null, 56],
  // })),
  logo: css(mq({
  })),
  logoImg: css(mq({
    display: 'block',
    height: [22, null, 36],
  })),
  logoScrolled: css(mq({

  })),

  top2: css(mq({
    height: [60, null, 90],
    background: 'black',
    position: 'sticky',
    top:'0px',
    width: '100%',
    zIndex: '999',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'all 0.3s'
  })),
  topScrolled2: css(mq({
    //height: [44, null, 56],
  })),
  logo2: css(mq({
  })),
  logoImg2: css(mq({
    display: 'block',
    margin: 'auto',
    height: [22, null, 30],
  })),
  logoScrolled2: css(mq({

  })),
  container2 :{
    position:'relative',
    width:'100%',
  }
}