const overloading = {
  breakpointNames: ['sm', 'md', 'lg', 'xl', 'xxl'],
  breakpoints: [576, 768, 992, 1200, 1600]
}

const colors = {
  blueGray : '#4f6283',
  darkGray : '#3b3b3b',
  red : '#a22932',
  lightGray : '#ebebeb'
}

const variables = {
  familyBase: '"proxima-nova",sans-serif',
  familyHeader: '"proxima-nova",serif',

  primaryColor: colors.blueGray,
  fontColor: colors.darkGray,
  bgColor: '#ebebeb',

  ...colors,
  ...overloading
}

export default variables;