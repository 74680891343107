/** @jsx jsx */
import { jsx } from "@emotion/core";

import { useState, Fragment } from "react";
import { SiteLink } from "../../components";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { NavBar } from "./navBar";
import { BackButton } from "../backButton/backButton";
import { Hamburger } from "../hamburger/hamburger";
import style from "./topHeaderStyle";

export function TopHeader(props) {
  const [menuOpened, setMenuOpened] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const type = props.type || "none__menu";

  useScrollPosition(
    ({ prevPos, currPos }) => {
      //console.log(currPos.x)
      //console.log(currPos.y);
      if (currPos.y > 90) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
      //setScrolled(currPos.y > 90)
    },
    [],
    null,
    true
  );

  function toggleMenu(e) {
    setMenuOpened(!menuOpened);
  }

  function onCloseMenu() {
    setMenuOpened(false);
  }
  function backTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
  if (type === "menu") {
    return (
      <Fragment>
        <header
          css={[style.top2, scrolled && style.topScrolled2]}
          id='top'
          className={type}
        >
          <div css={[style.container2]}>
            <BackButton />
            <SiteLink
              to='/'
              css={[style.logo2, scrolled && style.logoScrolled2]}
              onClick={backTop}
            >
              <img
                css={style.logoImg2}
                src='/assets/logos/logo.png?v=2'
                alt='logo'
              />
            </SiteLink>
            <Hamburger
              opened={menuOpened}
              toggleMenu={toggleMenu}
              scrolled={scrolled}
            />
            <NavBar open={menuOpened} onCloseMenu={onCloseMenu}></NavBar>
          </div>
        </header>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <header
        css={[style.top, scrolled && style.topScrolled]}
        id='top'
        className={type}
      >
        <BackButton />
        <SiteLink
          to='/'
          css={[style.logo, scrolled && style.logoScrolled]}
          onClick={backTop}
        >
          <img
            css={style.logoImg}
            src='/assets/logos/logo.png?v=1'
            alt='logo'
          />
        </SiteLink>
        <Hamburger
          opened={menuOpened}
          toggleMenu={toggleMenu}
          scrolled={scrolled}
        />
        <NavBar open={menuOpened} onCloseMenu={onCloseMenu}></NavBar>
      </header>
    </Fragment>
  );
}
