import React, { Fragment, useEffect } from 'react';
import cmsUtils from '../../utils/cmsUtils';
import utils from '../../../utils';
import { SiteLink } from '../../../components';
import { TopHeader } from '../../../layout/topHeader/topHeader';

export function SingleBanner(props) {
  const item = props.item;
  const cmsOption = props.cmsOption || {};
  const type = props.type || ""; // menu

  //console.log('item', item);
  const heightSet = cmsUtils.payload(item, 'HeightSet') || 'standard';
  const alignV = cmsUtils.payload(item, 'AlignV') || '50%';

  const cssClass = utils.classNames('cms_item', 'single-banner', 'banner-item', type, `bannerSize--${heightSet}`, item.cssClass || item.anchorName || '');
  const bgUrl = utils.site.resourcePath(cmsUtils.payload(item, 'ImageUrl'));

  const captionTitle = cmsUtils.payload(item, 'CaptionTitle2') || cmsUtils.payload(item, 'CaptionTitle');
  const linkUrl = cmsUtils.payload(item, 'LinkUrl2') || cmsUtils.payload(item, 'LinkUrl');

  const titlePanel = captionTitle ? (
    linkUrl ? (<SiteLink to={linkUrl} className="banner-item__link">
      <div className="banner-item__title" dangerouslySetInnerHTML={{ __html: captionTitle }}></div>
    </SiteLink>) : (<div className="banner-item__title" dangerouslySetInnerHTML={{ __html: captionTitle }}></div>)
  ) : null;

  useEffect(() => {
    const _none__menuHeader = document.querySelector("#top.none__menu");
      if(_none__menuHeader){
        _none__menuHeader.style.display = 'none';
      }
  }, []);

  return (
    <Fragment>
      <div className={cssClass} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}
        style={{ backgroundImage: utils.css.bgUrlStyle(bgUrl), backgroundPositionY: alignV }}>
        {titlePanel && <div className="banner_item__text">{titlePanel}</div>}
      </div>
      {
        type === "menu" &&
        <TopHeader type="menu"></TopHeader>
      }
    </Fragment>
  );
}