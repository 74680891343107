import { CmsRadioSender } from './cms-radio-sender';
import { CmsRadioReceiver } from './cms-radio-receiver';
import { CmsHtmlEditor } from './cms-html-editor';
import env from '../../env';

// declare var CKEDITOR: any;
// import * as $ from 'jquery';

export class CmsPageController {
  settings; //: any;
  sender; //: CmsRadioSender;
  receiver; //: CmsRadioReceiver;
  htmlEditor; //: CmsHtmlEditor;

  constructor() {
    this.settings = { root: 'body', viewOnly: false };
    this.sender = new CmsRadioSender(this);
    this.receiver = new CmsRadioReceiver(this);
    this.htmlEditor = new CmsHtmlEditor(env.cmsEndpoint, this.sender);
  }
  init(options) {
    const $ = window['jQuery'];
    this.settings = Object.assign(
      {},
      /*default*/ { root: 'body', viewOnly: false },
      options
    );
    this.initGlobalVars();

    var self = this;
    $('[onclick]').prop('onclick', null);
    $('[dblclick]').prop('dblclick', null);
    $(self.settings.root).off('click dblclick', '*');
    $(self.settings.root).on('click dblclick', '*', function (e) {
      //$(".cms_html_editor_img_selected").removeClass("cms_html_editor_img_selected");
      //                console.log($(e.target).hasClass("allowClick"));
      //                console.log(e.target);

      if (!$(e.target).hasClass('allowClick')) {
        e.stopPropagation();
        e.preventDefault();
      }

      if (
        $(this).attr('contenteditable') === 'true' ||
        $('#cms_html_editor_source_id').is(':visible')
      ) {
        e.stopPropagation();
        return;
      }
      if (!self.settings.viewOnly)
        self.selectItem($(this), false, e.type === 'dblclick');

      //Add noreferrer noopener when new window
      $('#cke_108_select').change(function () {
        let _value = $(this).val();
        $('#cke_215_textInput').val('');
        if (_value === '_blank') {
          $('#cke_215_textInput').val('noreferrer noopener');
        }
      });
    });

    if (self.settings.viewOnly) return; // stop the editing logic for viewonly mode.

    // ctrl + s for html block
    $(self.settings.root).on('keydown', '#cmsCurrentEditor', function (e) {
      if (e.ctrlKey && e.which === 83) {
        //var itemId = $(this).data("cms-item-id");
        //if (!itemId)
        //    itemId = $(this).closest("[data-cms-item-id]").data("cms-item-id");
        //window.parent.cms.site.saveHtml(itemId);
        //e.preventDefault();

        self.sender.saveAllDirtyHtml(self.getAllDirtyHtml());
        return false;
      }
    });
  }

  selectItemFromController(id) {
    const $ = window['jQuery'];
    var item = $(".cms_item[data-cms-item-id='" + id + "']");
    this.scrollTo(item);
    this.selectItem(item, true);
  }

  selectItem($item, fromController, doubleclicked = false) {
    //console.log("select item - " + fromController + " - " + doubleclicked);
    //console.log($item);
    var editor = $item.closest('[contenteditable]');
    if (editor.length > 0) return;
    var cke_top = $item.closest('.cke_top');
    if (cke_top.length > 0) return;

    this.closeEditingItem();
    //console.log($item);
    if (typeof $item === 'undefined') return;
    if ($item.length === 0) return;
    this.findItem($item, fromController, 0, doubleclicked);
  }

  closeEditingItem() {
    const $ = window['jQuery'];
    //console.log("close editing item");
    $('[contenteditable]').removeAttr('contenteditable');
    $('#cms_hovermenu').remove();
    $('.cms_editing_item').removeClass('cms_editing_item');
  }

  findItem($item, fromController, depth, doubleclicked) {
    const $ = window['jQuery'];
    if (depth > 100) return false;
    // console.log($item, fromController, depth, doubleclicked)
    if (
      typeof $item.attr('data-cms-html') !== 'undefined' &&
      $item.attr('data-cms-html') === 'true'
    ) {
      $item.attr('contenteditable', 'true');
      this.showHtmlEditor($item);
    }
    if (typeof $item.attr('data-cms-item-id') !== 'undefined') {
      //$item.attr("contenteditable", "true");
      $item.addClass('cms_editing_item');
      if ($item.hasClass('multiColumnPanelv2')) {
        //$(".cms_item[data-cms-item-id='" + itemid + "']")
        $item.find('div.new__multicolumn__panel__html').each(function (index) {
          $(this).addClass('cms_editing_item');
        });
      }

      if (!fromController) {
        //window.parent.cms.site.selectControllerItem($item.attr("data-cms-item-id"),
        //    typeof doubleclicked != "undefined" && doubleclicked == true
        //);
        this.sender.selectControllerItem({
          id: $item.attr('data-cms-item-id'),
          openEdit:
            typeof doubleclicked !== 'undefined' && doubleclicked === true,
        });
      }
      //show_hovermenu($item);
    } else {
      var editableParent = $item.parents(
        '.cms_item[data-cms-item-id], [data-cms-html]'
      );
      if (editableParent.length > 0)
        this.findItem(
          editableParent.first(),
          fromController,
          depth + 1,
          doubleclicked
        );
    }
  }

  sameContent(a, b) {
    return a === b;
  }

  showHtmlEditor($item) {
    const $ = window['jQuery'];
    var editor = $item;
    //console.log(editor);
    //var $menu = $("#cms_html_editor_ctrl");
    ////$menu.css("top", $item.offset().top - 40);
    ////$menu.css("left", $item.offset().left);
    //$menu.show();
    //initHtmlEditor($item);

    if (editor.attr('id') === 'cmsCurrentEditor') return;

    $('#cmsCurrentEditor').attr('id', '');
    this.htmlEditor.close(editor);

    if (editor.hasClass('new__multicolumn__panel__html')) {
      //$item.attr("data-cms-item-id")
      var dataCmsItemId2 = editor.attr('data-cms-item-id2');
      $("div.cms_item[data-cms-item-id2='" + dataCmsItemId2 + "']").attr(
        'data-cms-item-id',
        ''
      );
      editor.attr('data-cms-item-id', dataCmsItemId2);
    }

    editor.attr('id', 'cmsCurrentEditor');
    //editor.data("current-content", editor.html());

    this.htmlEditor.show(editor);
    //setHandlers($item);
  }

  initGlobalVars() {
    const $ = window['jQuery'];
    window['cmsPage'] = $.extend({}, window['cmsPage'] || {}, {
      htmleditor: {
        // cmsPage.htmleditor.getAnchors();
        getAnchors: function (opt) {
          //var options = $.extend({}, opt);
          //console.log(options);
          var anchors = [];

          $('a[name]').each(function (inx, obj) {
            anchors.push({ id: $(obj).attr('id'), name: $(obj).attr('name') });
          });

          $('[data-cms-item-name],[data-cms-anchor]').each(function (inx, obj) {
            if (
              typeof $(obj).attr('data-cms-anchor') !== 'undefined' &&
              $(obj).attr('data-cms-anchor') !== '' &&
              $(obj).parents('.owl-item').length === 0
            ) {
              anchors.push({ id: null, name: $(obj).attr('data-cms-anchor') });
            } else if (
              $(obj).attr('data-cms-item-name') !== '' &&
              $(obj).parents('.owl-item').length === 0
            ) {
              anchors.push({
                id: null,
                name: $(obj).attr('data-cms-item-name'),
              });
            }
          });

          return anchors;
        },
      },
    });
  }

  scrollTo(target) {
    const $ = window['jQuery'];
    if (typeof target === 'undefined') return;
    if ($(target).length === 0) return;
    var htmlbody = $('html,body');
    var top = 0;
    if ($(target).height() > $(window).height()) top = $(target).offset().top;
    else {
      top =
        $(target).offset().top -
        Math.floor(($(window).height() - $(target).height()) / 2);
    }
    //console.log(top);
    //htmlbody.animate({ scrollTop: top }, 500, 'easeInOutQuint');
    htmlbody.animate({ scrollTop: top }, 500);
  }

  getCurrentHtml() {
    const CKEDITOR = window['CKEDITOR'] || {};
    try {
      return CKEDITOR.instances.cmsCurrentEditor.getData();
    } catch (e) {
      return '';
    }
  }

  getAllDirtyHtml() {
    const $ = window['jQuery'];
    var self = this;
    var htmlChanges = [];
    $('.dirtyHtml').each(function (inx, obj) {
      var $section = $(obj);
      var content = $section.is('#cmsCurrentEditor') // if the html editor is active, it needs to get the html from the editor.
        ? self.getCurrentHtml()
        : $section.html();
      if ($section.is('[data-cms-section-id]')) {
        // section level?
        let $item = $section.closest('[data-cms-item-id]');
        htmlChanges.push({
          ItemId: $item.data('cms-item-id'),
          SectionId: $section.data('cms-section-id'),
          Content: content,
        });
      } else if ($section.is('[data-cms-item-id]')) {
        // section level?
        let $item = $section;
        if ($item.hasClass('new__multicolumn__panel__html')) {
          htmlChanges.push({
            ItemId: $item.data('cms-item-id'),
            SectionId: 'Content',
            SectionId2: 'Content2',
            Content: $(
              ".cms_item.item__01[data-cms-item-id2='" +
                $item.data('cms-item-id2') +
                "']"
            ).html(),
            Content2: $(
              ".cms_item.item__02[data-cms-item-id2='" +
                $item.data('cms-item-id2') +
                "']"
            ).html(),
          });
        } else {
          htmlChanges.push({
            ItemId: $item.data('cms-item-id'),
            SectionId: 'Content',
            Content: content,
          });
        }
      }
    });
    return htmlChanges;
  }

  getHtml(itemid: any) {
    const $ = window['jQuery'];
    this.htmlEditor.close();
    var self = this;

    var item = $(".cms_item[data-cms-item-id='" + itemid + "']");
    var formvals = { Content: this.cms_replace_item_html(item) };

    if (item.hasClass('new__multicolumn__panel__html')) {
      formvals = {
        Content: this.cms_replace_item_html(
          $(".cms_item.itme__01[data-cms-item-id='" + itemid + "']")
        ),
        Content2: this.cms_replace_item_html(
          $(".cms_item.itme__02[data-cms-item-id='" + itemid + "']")
        ),
      };
    }

    //cms_msg_progressing("#save_message", "Saving...");
    if ($('[data-cms-section-id]', item).length > 0) {
      $('[data-cms-section-id]', item).each(function (inx, section) {
        var closestItemId = $(section).closest('.cms_item').data('cms-item-id');
        if (closestItemId === itemid) {
          var id = $(section).attr('data-cms-section-id');
          formvals[id] = self.cms_replace_item_html($(section)); //.find(">:first-child")().html();
        }
      });
    }
    return formvals;
  }

  cms_replace_item_html(item) {
    return item.html();
    // var $tmp = $("<div></div>").html(item.html());
    // if ($tmp.find('.cms-skeleton').length > 0)
    //   return $tmp.find('.cms-skeleton').html();
    // else
    //   return item.html();
    //tmp.find(".cms_ctrl_in_editor").each(function (inx, obj) {
    //    var ctrlid = $(obj).attr("data-cms-ctrl-id");
    //    $(obj).replaceWith("__cms__ctrl:" + ctrlid + "__");
    //});
    //return tmp.html();
  }
}
